<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.resourceallocation.authorize')"
			:modelName="modelName"
			dialogName="authorizeResourceAllocation"
			:onShow="onShowModalAuthorize"
			:onOk="onOkAuthorizeResourceAllocation"
			:showDialogProp="showAuthorizeResourceAllocation"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`authstartdate-resourceallocation`"
							v-model="model.authstartdate"
							:label="$t('form.resourceallocation.authstartdate')"
							required
							toplabel
							time
							:max="model.authenddate"
						></pui-date-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-date-field
							:id="`authenddate-resourceallocation`"
							v-model="model.authenddate"
							:label="$t('form.resourceallocation.authenddate')"
							required
							toplabel
							time
							:min="model.authstartdate"
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourceallocation.initiate')"
			:modelName="modelName"
			dialogName="initiateResourceAllocation"
			:onShow="onShowModalInitiate"
			:onOk="onOkInitiateResourceAllocation"
			:showDialogProp="showInitiateResourceAllocation"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="">
						<pui-date-field
							:id="`actualstartdate-resourceallocation`"
							v-model="model.actualstartdate"
							:label="$t('form.resourceallocation.actualstartdate')"
							required
							toplabel
							time
							:max="model.actualenddate"
						></pui-date-field>
					</v-col>
					<v-col cols="6" v-if="new Date(model.actualstartdate) > new Date(model.authstartdate)">
						<pui-select
							:id="`delayreason-${modelName}`"
							:attach="`delayreason-${modelName}`"
							v-model="model"
							:label="$t('form.resourceallocation.delayreason')"
							toplabel
							clearable
							required
							modelName="delayreason"
							:itemsToSelect="[{ id: model.delayreason }]"
							:modelFormMapping="{ id: 'delayreason' }"
							itemValue="id"
							itemText="description"
							reactive
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourceallocation.end')"
			:modelName="modelName"
			dialogName="endResourceAllocation"
			:onShow="onShowModalEnd"
			:onOk="onOkEndResourceAllocation"
			:showDialogProp="showEndResourceAllocation"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="8">
						<pui-date-field
							:id="`actualenddate-resourceallocation`"
							v-model="model.actualenddate"
							:label="$t('form.resourceallocation.actualenddate')"
							required
							toplabel
							time
							:min="model.actualstartdate"
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourceallocation.cancel')"
			:modelName="modelName"
			dialogName="cancelResourceAllocation"
			:onShow="onShowModalCancel"
			:onOk="onOkCancelResourceAllocation"
			:showDialogProp="showCancel"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						{{ $t('modal.resourceallocation.cancelConfirmMessage') }}
					</v-col>
					<v-col cols="12">
						<pui-select
							:id="`cancelationreason-${modelName}`"
							:attach="`cancelationreason-${modelName}`"
							v-model="model"
							:label="$t('form.resourceallocation.cancelationreason')"
							toplabel
							clearable
							modelName="cancelationreason"
							:itemsToSelect="[{ id: model.cancelationreason }]"
							:modelFormMapping="{ id: 'cancelationreason' }"
							itemValue="id"
							itemText="description"
							reactive
						></pui-select>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resourceallocation.validate')"
			:modelName="modelName"
			dialogName="validateResourceAllocation"
			:onShow="onShowModalValidate"
			:onOk="onOkValidate"
			:showDialogProp="showValidateResourceAllocation"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="6">
						<pui-date-field
							:id="`actualstartdate-resourceallocation`"
							v-model="model.actualstartdate"
							:label="$t('form.resourceallocation.actualstartdate')"
							required
							toplabel
							time
							:max="model.actualenddate"
						></pui-date-field>
					</v-col>
					<v-col cols="6">
						<pui-date-field
							:id="`actualenddate-resourceallocation`"
							v-model="model.actualenddate"
							:label="$t('form.resourceallocation.actualenddate')"
							required
							toplabel
							time
							:min="model.actualstartdate"
						></pui-date-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import EventsMixin from '@/mixins/EventsMixin';

export default {
	name: 'resourceallocationmodals',
	mixins: [EventsMixin],
	components: {},
	data() {
		return {
			model: {},
			showAuthorizeResourceAllocation: true,
			showInitiateResourceAllocation: true,
			showEndResourceAllocation: true,
			showCancel: true,
			showValidateResourceAllocation: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	computed: {},
	watch: {},
	methods: {
		onShowModalAuthorize(modalData) {
			this.model = modalData;
			this.model.authstartdate = this.model.authstartdate ? this.model.authstartdate : this.model.startdate;
			this.model.authenddate = this.model.authenddate ? this.model.authenddate : this.model.enddate;
		},
		onShowModalInitiate(modalData) {
			this.model = modalData;
			this.model.actualstartdate = this.model.actualstartdate ? this.model.actualstartdate : this.model.authstartdate;
		},
		onShowModalEnd(modalData) {
			this.model = modalData;
			this.model.actualenddate = this.model.actualenddate ? this.model.actualenddate : this.model.authenddate;
		},
		onShowModalCancel(modalData) {
			this.model = modalData;
		},
		onShowModalValidate(modalData) {
			this.model = modalData;
		},
		onOkAuthorizeResourceAllocation(modalData) {
			return new Promise(async (resolve) => {
				await this.doAuthorizeResourceAllocation(modalData);
				resolve(true);
			});
		},
		async doAuthorizeResourceAllocation(modalData) {
			this.$puiRequests.postRequest(
				'/resourceallocation/authorize',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showAuthorizeResourceAllocation = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showAuthorizeResourceAllocation = false;
				}
			);
		},
		onOkInitiateResourceAllocation(modalData) {
			return new Promise(async (resolve) => {
				await this.doInitiateResourceAllocation(modalData);
				resolve(true);
			});
		},
		async doInitiateResourceAllocation(modalData) {
			this.$puiRequests.postRequest(
				'/resourceallocation/initiate',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showInitiateResourceAllocation = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showInitiateResourceAllocation = false;
				}
			);
		},
		onOkEndResourceAllocation(modalData) {
			return new Promise(async (resolve) => {
				await this.doEndResourceAllocation(modalData);
				resolve(true);
			});
		},
		async doEndResourceAllocation(modalData) {
			this.$puiRequests.postRequest(
				'/resourceallocation/end',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEndResourceAllocation = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showEndResourceAllocation = false;
				}
			);
		},
		onOkCancelResourceAllocation(modalData) {
			return new Promise(async (resolve) => {
				await this.doCancelResourceAllocation(modalData);
				resolve(true);
			});
		},
		async doCancelResourceAllocation(modalData) {
			this.$puiRequests.postRequest(
				'/resourceallocation/cancel',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancel = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancel = false;
				}
			);
		},
		onOkValidate(modalData) {
			return new Promise(async (resolve) => {
				await this.doValidateResourceAllocation(modalData);
				resolve(true);
			});
		},
		async doValidateResourceAllocation(modalData) {
			this.$puiRequests.postRequest(
				'/resourceallocation/validate',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancel = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showCancel = false;
				}
			);
		}
	}
};
</script>
